
import 'slick-carousel';
import clsSlider from './class.sliders';


SE.clsPortfolio = (function() {
    //INIT
    function init(){
      if ($("#portfolio").length == 0) return;
      
      for (var i = 1; i < 4; i++) {
        slider_create(i);
      }
      
    }

    // SLIDER 1
      
    function slider_create(numberid) {
       var mainobj = '#kharisslider_0' + numberid;
       var totalslide =  $(mainobj + '>div').length;
       var mainnav = '#kharisslider_asNavFor_0' + numberid;
       

       $(mainobj).slick({
        ...SE.para_defauts_options,
         asNavFor: mainnav,
       });
 
       $(mainnav).slick({
        ...SE.para_defauts_options,
        ...SE.para_defauts_responsive_02,
        slidesToShow: 2.5,
        asNavFor: mainobj
      });
      
      var slider = new clsSlider({ turnon : false, total:totalslide });
      slider.init({main : mainobj,nav : mainnav});
      
    }

    //RETURN
    return {    
      init:init
    }
  })();
  
  SE.clsPortfolio.init();