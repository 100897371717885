
import 'slick-carousel';
import clsSlider from './class.sliders';

SE.clsApproach = (function() {
    //INIT
    function init(){
      if ($(".ourapproach").length == 0) return;

      slider_create();
    }

    // SLIDER
      
    function slider_create() {
      var mainobj = '#kharisslider';
      var mainnav = '#kharisslider_asNavFor'
      var totalslide =  $(mainobj + '>div').length;

      $(mainobj).removeClass('loading');

      $(mainobj).slick({
        ...SE.para_defauts_options,
        slidesToShow: 1,
        asNavFor: mainnav
      });

      $(mainnav).slick({
        ...SE.para_defauts_options,
        ...SE.para_defauts_responsive_01,
        slidesToShow: 5,
        asNavFor: mainobj
      });
      
      var slider = new clsSlider({ turnon : false, total:totalslide });
      slider.init({main : mainobj,nav : mainnav});
    }
   
    //RETURN
    return {    
      init:init
    }
  })();
  
  SE.clsApproach.init();