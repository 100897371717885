import "slick-carousel";
import clsSlider from "./class.sliders";
import gsap from "gsap";

SE.clsEcosystem = (function() {
    //INIT
    function init() {
        if ($("#ecosystem").length == 0) return;

        $( window ).resize(function() {
        //   customLine();
          hideNullText();
        });

        // customLine();
        slider1_create();
        slider2_create();
        createDetail();
        hideNullText();
    }

    

    function createDetail() {

        var para_class = {
            testimonialItem : $('.testimonial-item'),
            text : $('.text'),
            textimg: $('.text.hide'),
            cta: $('.testimonial-item .cta'),
            detail: $('.testimonial-item .detail'),
            line: $('#kharisslider_asNavFor_ourecosystem_02 .line'),
            close: $('.testimonial-item .close-detail'),
            control: $('.testimonials .controls'),
        }

        openDetail(para_class);
        closeDetail(para_class);
    }

    function openDetail(para_class) {

        $('#kharisslider_ourecosystem_02 .slick-slide').each(function(i,e) {
            i = i + 1;
            $('#js-readmore' + i ).click(function(){
                $('#testimonial-item'+ i).addClass('open-detail');
                para_class.control.addClass('controls-blue');
                $('.group-slidercta').hide();
    
                var tl = gsap.timeline();
                tl
                    .to([para_class.text, para_class.cta], {duration: .5, opacity: 0, height: 0, ease:'None'})
                    .to(para_class.line, {duration: .5, opacity: 0, ease:'None'}, '-=.5')
                    .to([para_class.detail, para_class.textimg], {duration: .5, opacity: 1, height: "auto", ease:'None'})
                    .to(para_class.close, {duration: .5, opacity: 1, height: 30, ease:'None'}, '-=.5')
                    
            })
        })
       
        
    }

    function closeDetail(para_class) {
        var getWidth = $(window).width();
        var getHeight = $('.testimonial-image img').height();
        
        
        if (getWidth <= 992 || $('body').hasClass('Mobile')) {
            getHeight = getHeight + 1;
        }
        else {
            getHeight = 1;
        }

        $('#kharisslider_ourecosystem_02 .slick-slide').each(function(i,e) {
            i = i + 1;
            
            $('#testimonial-item'+ i + ' .close-detail').click(function(){
            
                $('#testimonial-item'+ i).removeClass('open-detail');
                para_class.control.removeClass('controls-blue');
                $('.group-slidercta').show();
                var tl = gsap.timeline();
                tl
                    .set(para_class.line, {top:  getHeight + 'px'})
                    .to([para_class.text, para_class.cta], {duration: .5, opacity: 1, height: "auto", ease:'None'})
                    .to(para_class.line, {duration: .5, opacity: 1, ease:'None'}, '-=.5')
                    .to([para_class.detail, para_class.textimg], {duration: .5, opacity: 0, height: 0, ease:'None'})
                    .to(para_class.close, {duration: .5, opacity: 0, height: 0, ease:'None'}, '-=1')
                    
            })

        })
    }

    function hideNullText() {
        var para_obj = {
            left_content_h5: $('.carrousel_text_content .inner .left_content h5'),
            left_content: ' .inner .left_content',
        }

        var width = $(window).width();

       
        $('.carrousel_text_content').each(function(i, e){
            var text = $(e).find(para_obj.left_content_h5).text().trim();
            if($('body').hasClass('Mobile') || width <= 992) {
                if (text.length == 0) {
                    var class_obj = $(this).attr('id');
                    $('#' + class_obj + para_obj.left_content).hide();
                }
            }
            else {
                if (text.length == 0) {
                    var class_obj = $(this).attr('id');
                    $('#' + class_obj + para_obj.left_content).show();
                }
            }
        })
     

  
        // if (para_obj.left_content.text() != null)
    }

    // SLIDER

    function slider1_create() {
        var mainobj = "#kharisslider_ourecosystem_01";
        var mainnav = "#kharisslider_asNavFor_ourecosystem_01";
        var totalslide = $(mainobj + ">div").length;

        $(mainobj).removeClass("loading");

        $(mainobj).slick({
            ...SE.para_defauts_options,
            // ...SE.para_defauts_responsive_02,
            asNavFor: mainnav
        });

        // kharisslider_asNavFor
        $(mainnav).slick({
            ...SE.para_defauts_options,
            ...SE.para_defauts_responsive_03,
            slidesToShow: 6.5,
            swipe: true,
            swipeToSlide: true
            // asNavFor: mainobj
        });

        var slider = new clsSlider({ turnon: false, total: totalslide });
        slider.init({ main: mainobj, nav: mainnav });
    }

    function slider2_create() {
        var mainobj = "#kharisslider_ourecosystem_02";
        var mainnav = "#kharisslider_asNavFor_ourecosystem_02";
        var totalslide = $(mainobj + ">div").length;

        $(mainobj).removeClass("loading");

        $(mainobj).slick({
            ...SE.para_defauts_options,
            // adaptiveHeight: true,
            asNavFor: mainnav
        });

        // kharisslider_asNavFor
        $(mainnav).slick({
            ...SE.para_defauts_options,
            asNavFor: mainobj
        });

        var slider = new clsSlider({ turnon: true, total: totalslide });
        slider.init({ main: mainobj, nav: mainnav });
    }

    // function customLine() {
    //   var getWidth = $(window).width();
    //   var getHeight = $('.testimonial-image img').height();
      
    //   if (getWidth <= 992 || $('body').hasClass('Mobile')) {
       
    //     $('#kharisslider_asNavFor_ourecosystem_02 .line').css('top', getHeight + 1 + 'px');
    //     }
    //   else {
    //     $('#kharisslider_asNavFor_ourecosystem_02 .line').css('top', 1 + 'px');
    //   }
      
    // }

   

    

    //RETURN
    return {
        init: init
    };
})();

SE.clsEcosystem.init();
