var clsSlider = function (arr_autoplay) {
    //PARAMATERS
    var para_slider = new Object();
    var arrobjs = null;
    const mapParamsSliderToEventPage = {
        '#kharisslider': {
            category: 'kharis-ourapproach',
            actionLeft: 'btn-left-arrow',
            actionRight: 'btn-right-arrow',
            actionPause: 'btn-pause',
            actionPlay: 'btn-play'
        },
        '#kharisslider_ourecosystem_01': {
            category: 'kharis-teamandadvisors',
            actionLeft: 'btn-person-left-arrow',
            actionRight: 'btn-person-right-arrow',
            actionPause: 'btn-person-pause',
            actionPlay: 'btn-person-play'
        },
        '#kharisslider_ourecosystem_02': {
            category: 'kharis-teamandadvisors',
            actionLeft: 'btn-testimonial-left-arrow',
            actionRight: 'btn-testimonial-right-arrow',
            actionPause: 'btn-testimonial-pause',
            actionPlay: 'btn-testimonial-play'
        },
        "#kharisslider_01": {
            category: 'kharis-brands',
            actionLeft: 'btn-car1-left-arrow',
            actionRight: 'btn-car1-right-arrow',
            actionPause: 'btn-car1-pause',
            actionPlay: 'btn-car1-play'
        },
        "#kharisslider_02": {
            category: 'kharis-brands',
            actionLeft: 'btn-car2-left-arrow',
            actionRight: 'btn-car2-right-arrow',
            actionPause: 'btn-car2-pause',
            actionPlay: 'btn-car2-play'
        },
        "#kharisslider_03": {
            category: 'kharis-brands',
            actionLeft: 'btn-car3-left-arrow',
            actionRight: 'btn-car3-right-arrow',
            actionPause: 'btn-car3-pause',
            actionPlay: 'btn-car3-play'
        }
    }

    //INIT
    function init(arr) {
        arrobjs = arr;

        $.each(SE.para_slider, function (key, value) {
            if (key.includes("div_"))
                para_slider[key] = arr.main + value;
            else
                para_slider[key] = value;
        });

        $(window).resize(function () {
            resize();
        });

        resize();
        slider_events();
        slider_reset();
        slidernavfor_events();
        autoplay_init();
        autoplay_event();
        open_isdetail();
    }

    // WINDOW
    function resize() {
        if ($(window).width() <= 992 && $(window).width() > 540) {
            para_slider.arrowpos_start = SE.para_slider.arrowpos_tabletstart
        }
        else if ($(window).width() <= 540) {
            para_slider.arrowpos_start = SE.para_slider.arrowpos_mobilestart
        }
        else {
            para_slider.arrowpos_start = SE.para_slider.arrowpos_start
        }
    }

    // NAV FOR
    var para_nav = {
        way: 0, // 1:next , 2:prev, 3:goto
        line_w: '20%',
        flag: false,
    }


    var para_linenav = {
        objcurrent: null,
        objnextcurrent: null,
        objnotcurrent: null
    }



    function slidernavfor_events() {
        if ($(arrobjs.nav).length == 0) return;

        $(arrobjs.nav).on('click touch', '.slick-slide', function () {
            var intnumber = parseInt($(this).attr('data-slick-index'));
            if (para_slider.div_obj == "#kharisslider_ourecosystem_01") {
                const etAction = $(this).find('.carousel-label-item').attr('etaction') || '';
                var total = $(arrobjs.nav).slick("getSlick").slideCount;
                var slidesToShow = $(arrobjs.nav).get(0).slick.options.slidesToShow;
                var numbertocompare = Math.ceil(total - slidesToShow);
    
                if(intnumber >= numbertocompare) {
                    $(arrobjs.nav).slick('slickGoTo', numbertocompare, false);
                }

                active_line(intnumber+1);
                
                if (window.dataLayer) {
                    dataLayer.push({ 'event': 'Custom - Event', 'CustomEvent - Category': 'kharis-ecosystem', 'CustomEvent - Action': etAction, 'CustomEvent - Label': '' });
                }
            }
            
            
            $(para_slider.div_obj).slick('slickGoTo', intnumber, true);

            para_nav.flag = true;
            slidernavfor_reset(intnumber);
            autoplay_init();
            if (arr_autoplay.turnon) {
                autoplay_stop();
            }
        });
    }

    function slidernavfor_reset(intnumber) {
        if ($(arrobjs.nav).length == 0) return;

        if (para_autoplay.turnon) {
            para_nav.line_w = 0;
        }
        else {
            para_nav.line_w = '20%';
        }


        para_linenav.objcurrent = arrobjs.nav + ' .slick-slide:nth-child(' + slider_getcurrent() + ')  .line';
        para_linenav.objnotcurrent = arrobjs.nav + ' .slick-slide:not(:nth-child(' + slider_getcurrent() + '))  .line';
        para_linenav.objnextcurrent = arrobjs.nav + ' .slick-slide:nth-child(' + slider_getcurrent() + ') + .slick-active  .line';
        para_nav.way = 0;
        

        gsap.set(para_linenav.objnotcurrent, { width: 0, left: 0, opacity: 0 })
        gsap.to(para_linenav.objcurrent, { width: para_nav.line_w, left: 0, duration: .2 })

        // console.log(arrobjs.nav, intnumber)
        //$(arrobjs.nav).slick('slickGoTo', 8, false);
    }

    function slidernavfor_ani() {
        if ($(arrobjs.nav).length == 0) return;

        var obj_line1 = para_linenav.objcurrent;


        var tl = gsap.timeline();
        if (para_nav.way == 1) {
            var obj_line2 = para_linenav.objnextcurrent;

            tl
                .set(obj_line1, { width: 0 })
                .to(obj_line1, { left: '100%', duration: .4 })
                .to(obj_line2, { left: 0, width: para_nav.line_w, duration: .4 })
        } else if (para_nav.way == 2) {

            var obj_line2 = para_linenav.objcurrent;

            tl.to(obj_line1, { width: 0, duration: .2 })
        }
    }

    // CIRCLE LOGO
    var circlelogo_timeout = null;

    function circlelogo_hover_check () {
        return;  

        $(para_slider.div_obj + ' .slick-current .sliderprocess').each(function (index, element) {
            // element == this
            console.log(index,$(this).hasClass('hover'));
            
            if($(this).hasClass('hover'))
                circlelogo_hover_turnon($(this));
        });
        
    }

    function circlelogo_hover_turnon ($this) {

        if (para_slider.isrunning) {
            clearTimeout(circlelogo_timeout);

            circlelogo_timeout = setTimeout(() => {
                circlelogo_hover_turnon ($this);
            }, 400);
            return;
        }

        // over
        var status = $this.hasClass('next');
        slider_hoveranimation(status);
    }

    function circlelogo_hover_turnoff () {
        clearTimeout(circlelogo_timeout);
        if (para_slider.isrunning) return;
        // out

        para_slider.ani_hover.reverse();
    }

    // MAIN SLIDER
    function slider_events() {
        let eventDetail = mapParamsSliderToEventPage[para_slider.div_obj] || { category: '', actionLeft: '', actionRight: '', };
        $(para_slider.div_obj + ' .sliderprocess.next').on('click', function () {
            if (para_slider.isrunning) return;

            para_nav.way = 1;
            para_nav.flag = true;
            slider_run(true);
            if (arr_autoplay.turnon) {
                autoplay_stop();
            }
            active_line(slider_getcurrent()+1);
           
            dataLayer.push({ 'event': 'Custom - Event', 'CustomEvent - Category': eventDetail.category, 'CustomEvent - Action': eventDetail.actionRight, 'CustomEvent - Label': "" });
        });

        $(para_slider.div_obj + ' .sliderprocess.prev').on('click', function () {
            if (para_slider.isrunning) return;

            para_nav.way = 2;
            para_nav.flag = true;
            slider_run(false);
            if (arr_autoplay.turnon) {
                autoplay_stop();
            }
            active_line(slider_getcurrent()-1);

            dataLayer.push({ 'event': 'Custom - Event', 'CustomEvent - Category': eventDetail.category, 'CustomEvent - Action': eventDetail.actionLeft, 'CustomEvent - Label': "" });
        });


        /// HOVER CIRCLE LOGO aaaaa
        
        if ($('body').hasClass('Desktop')) {
        
            $(para_slider.div_obj + ' .sliderprocess').mouseover(function (e) { 
                $(this).addClass('hover');
                circlelogo_hover_turnon($(this));
            });

            $(para_slider.div_obj + ' .sliderprocess').mouseout(function () { 
                $(this).removeClass('hover');
                circlelogo_hover_turnoff();
            });

        }


        // SLIDER AFTER CHANGE
        $(para_slider.div_obj).on('afterChange', function (event, slick, currentSlide, nextSlide) {
            if (para_nav.way == 1 || para_nav.way == 2) {
                setTimeout(() => {
                    slider_reset();
                }, 100);
            }
            else {
                slider_reset();
            }
        });
    }

   

    function slider_reset() {
        para_slider.isrunning = false;

        var tl = gsap.timeline();

        tl
            .set(para_slider.div_slidecurrent_groupslidercta, { left: -para_slider.arrowpos_start })
            .set(para_slider.div_slidenext_groupslidercta, { left: para_slider.arrowpos_start })
            .to(para_slider.div_obj + ' .carrousel_text_content', { duration: .3, opacity: 1 })
            .to(para_slider.div_obj + ' .overlay', { duration: .3, opacity: 0 }, '-=.3');


        var numberindex = parseInt($(para_slider.div_obj + ' .slick-current').attr('data-slick-index')) ;
        var allcirclelogo_lt = para_slider.div_obj + ' .slick-slide:lt('+ (numberindex+1) +') .group-slidercta';  
        var allcirclelogo_gt = para_slider.div_obj + ' .slick-slide:gt('+numberindex+') .group-slidercta';

        $( allcirclelogo_lt ).css( {left: -para_slider.arrowpos_start} ); 
        $( allcirclelogo_gt ).css( {left: para_slider.arrowpos_start} ); 

        circlelogo_hover_check ();

    }


    function slider_hoveranimation(status) {

        var timehover = .2;
        var obj_overplay = para_slider.div_obj + ' .slick-current .overlay';
        var obj_buttontext = status ? para_slider.div_slidenext_text_next : para_slider.div_slidecurrent_text_prev;
        var obj_circle_b = status ? para_slider.div_circle_b_next : para_slider.div_circle_b_prev;
        var obj_circle_s = status ? para_slider.div_circle_s_next : para_slider.div_circle_s_prev;
        var color_para = {
            border: '',
            bg: '',
        }
        para_slider.ani_hover = null; // clear timeline if it exists
        para_slider.ani_hover = gsap.timeline({
            onReverseComplete: function () {
                gsap.set([obj_buttontext, obj_overplay], { opacity: 0 })
                gsap.set(obj_circle_b, { width: '100%', height: '100%', borderWidth: 6 })
                gsap.set(obj_circle_s, { backgroundColor: 'transparent' })

            }
        }); // create timeline

        if ($('.group-slidercta').hasClass('logoblue')) {
            color_para.border = '#071e2e';
            color_para.bg = '#fff';
        }
        else {
            color_para.border = '#fff';
            color_para.bg = '#071e2e';
        }

        

        // set init
        para_slider.ani_hover

            //set bg overlay
            .set(obj_overplay, { backgroundColor: color_para.bg })
            .set(obj_buttontext, { opacity: 0 })

            // animate circle
            .to(obj_circle_b, { duration: timehover, width: '120%', height: '120%', borderWidth: 8, ease: "none" })
            .to(obj_circle_s, { duration: timehover, backgroundColor: color_para.border, ease: "none" }, '-=' + timehover)


            // animate bg black
            .to(obj_overplay, { duration: timehover, opacity: .7, ease: "none" }, '-=' + timehover)

            // animate text near the logo
            .to(obj_buttontext, { duration: timehover, opacity: 1, ease: "none" }, '-=' + timehover)

    }


    function slider_run(status) {
        para_slider.isrunning = true;
        // autoplay_reset();

        var obj_overplay = para_slider.div_obj + ' .slick-current .overlay';
        var obj_buttontext = para_slider.div_obj + ' .button-text';
        var obj_circle_b = status ? para_slider.div_circle_b_next : para_slider.div_circle_b_prev;
        var obj_circle_s = status ? para_slider.div_circle_s_next : para_slider.div_circle_s_prev;
        var obj_groupslidercta = { // == 1
            div: para_slider.div_slidenext_groupslidercta,
            left: -para_slider.arrowpos_start
        };
        var slickmoving = 'slickNext'// 'slickPrev';
        var color_bg = '';

        if ($('.group-slidercta').hasClass('logoblue')) {
            color_bg = '#fff';
        }
        else {
            color_bg = '#000';
        }

        if (para_nav.way == 2) {
            obj_groupslidercta = {
                div: para_slider.div_slidecurrent_groupslidercta,
                left: para_slider.arrowpos_start
            };

            slickmoving = 'slickPrev'
        }

        var tl = gsap.timeline({
            onComplete: function () {
                autoplay_init();
            }
        });

        tl
            // set init
            .set(para_slider.div_nocurrent_textcontent, { opacity: 0 })
            .set(obj_overplay, { backgroundColor: color_bg })

            // call run slider
            .add(function () {
                slidernavfor_ani();

                if (para_nav.way == 0) {
                    $(para_slider.div_obj).slick('slickGoTo', 0, true);

                } else {
                    $(para_slider.div_obj).slick(slickmoving);
                }
            })

            // hide bg black + text near the logo
            .to(obj_overplay, { duration: .2, opacity: .7 })
            .to(obj_buttontext, { duration: .2, opacity: 0 }, '-=.2')
            .to(obj_circle_s, { duration: .2, backgroundColor: 'transparent', ease: "none" }, '-=.2')
            .to(obj_circle_b, { duration: .2, width: '100%', height: '100%', borderWidth: 6, ease: "none" }, '-=.2')


            // animate return near the logo to first position
            .to(obj_groupslidercta.div, { duration: .2, left: 0, ease: "none" }, '-=.2')
            .to(obj_groupslidercta.div, { duration: .2, left: obj_groupslidercta.left, ease: "none" }, '+=' + (para_slider.speed / 1000 - .2))
    }

    function slider_getcurrent() {
        return $(para_slider.div_obj).slick('slickCurrentSlide') + 1;
    }

    // AUTOLAY
    var para_autoplay = {
        turnon: arr_autoplay.turnon,
        total: arr_autoplay.total,
        speed: 15,
        objloop: null
    }

    function autoplay_init() {
        if(para_nav.flag) return;
        autoplay_reset();
        slidernavfor_reset();

        var b_pause = autoplay_ispause() ? true : false;

        if (para_autoplay.turnon) {
            para_autoplay.objloop = gsap.timeline({
                paused: b_pause, onComplete: function () {
                    autoplay_checklastslide();
                }
            });
            

            para_autoplay.objloop
                .set(para_linenav.objcurrent, {opacity: 1})
                .to(para_linenav.objcurrent, { width: '100%', duration: para_autoplay.speed, ease: "none" })
                .set(para_linenav.objcurrent, { right: 0, left: "auto" })
                .to(para_linenav.objcurrent, { width: 0, duration: .5, ease: "none" }, '+=.2')
//aaaa
        }
    }

    function autoplay_event() {
        // hover slider events
        $('.js-autoplay-hoverstop').hover(function () {
            // over

            autoplay_pause();
        }, function () {

            // out
            autoplay_restart();
        }
        );

        $(para_slider.div_obj + ' + .controls .js-controls-pause').click(function (e) {
            autoplay_pause();
            let eventDetail = mapParamsSliderToEventPage[para_slider.div_obj] || { category: '', actionPause: '', };
            dataLayer.push({ 'event': 'Custom - Event', 'CustomEvent - Category': eventDetail.category, 'CustomEvent - Action': eventDetail.actionPause, 'CustomEvent - Label': "" });
        });

        $(para_slider.div_obj + ' + .controls .js-controls-play').click(function (e) {
            autoplay_restart();
            let eventDetail = mapParamsSliderToEventPage[para_slider.div_obj] || { category: '', actionPlay: '', };
            dataLayer.push({ 'event': 'Custom - Event', 'CustomEvent - Category': eventDetail.category, 'CustomEvent - Action': eventDetail.actionPlay, 'CustomEvent - Label': "" });
        });
    }

    function autoplay_reset() {
        if (para_autoplay.objloop == null) return;

        para_autoplay.objloop.kill();
        para_autoplay.objloop = null;
    }

    function autoplay_checklastslide() {
        var current = slider_getcurrent();

        if (current == arr_autoplay.total) {
            para_nav.way = 0;
        } else {
            para_nav.way = 1;
        }

        slider_run(true);

    }

    function autoplay_pause() {
        $(para_slider.div_obj + ' + .controls').removeClass('playing')

        if (!para_autoplay.turnon) return;
        if (para_autoplay.objloop == null) return;


        para_autoplay.objloop.pause();
    }

    function autoplay_stop() {
        para_autoplay.objloop.kill();
    }

    function autoplay_ispause() {
        return ($('.js-autoplay-hoverstop:hover').length != 0) || ($(para_slider.div_obj + ' + .controls').length > 0 && !$(para_slider.div_obj + ' + .controls').hasClass('playing'));

    }

    function autoplay_restart() {
        $(para_slider.div_obj + ' + .controls').addClass('playing')

        if (!para_autoplay.turnon) return;
        if (para_autoplay.objloop == null) return;


        para_autoplay.objloop.play();

    }

    function open_isdetail() {
        $(para_slider.div_obj + ' .testimonial-item .js-readmore').click(function (e) {
            autoplay_pause();
            let eventDetail = mapParamsSliderToEventPage[para_slider.div_obj] || { category: '', actionPause: '', };
            dataLayer.push({ 'event': 'Custom - Event', 'CustomEvent - Category': eventDetail.category, 'CustomEvent - Action': eventDetail.actionPause, 'CustomEvent - Label': "" });
        });

        $(para_slider.div_obj + ' .testimonial-item .close-detail').click(function (e) {
            autoplay_restart();
            let eventDetail = mapParamsSliderToEventPage[para_slider.div_obj] || { category: '', actionPlay: '', };
            dataLayer.push({ 'event': 'Custom - Event', 'CustomEvent - Category': eventDetail.category, 'CustomEvent - Action': eventDetail.actionPlay, 'CustomEvent - Label': "" });
        });
    }

    function active_line(number) {
        var line = arrobjs.nav + ' .slick-slide:nth-child(' + number + ')  .active-line';
        $('.slick-slide .active-line').hide();
        $(line).css('display', 'block');
    }

    //RETURN
    return {
        init: init
    }
};

export default clsSlider
